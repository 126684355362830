import React from 'react'
import { useTranslation } from 'react-i18next'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as AuthorizedIcon } from '../../svg/px-icon-education.svg'
import { Text, Heading } from '../../components/Library/Typography'
import { Footer } from '../../components/Footer'
import {
  Content,
  PrimaryButton,
  LinkButton,
  ListItem,
  List
} from '../../components/Library'
import { LogoHeader } from '../../components/LogoHeader'
import { Flex } from '../../components/Library/Flex'
import { Loading } from '../../components/Loading'
import { Icon } from '../../components/Library/Icon'

interface Props {
  urlToken: string
  consentContent: any
  locale: string
  onContinueButtonClick(): void
  onDeclineClick(): void
}


export const Layout: React.StatelessComponent<Props> = ({
  locale,
  consentContent,
  onContinueButtonClick,
  onDeclineClick
}) => {
  const { t } = useTranslation()
  const { consent_content, printing_enabled } = consentContent
  if (!consent_content) {
    return (<Loading />)
  }

  const sanitizeHTML = (message: string, message1: string ) => {
    const aTag = `<a href="mailto:support@navigatingcare.com">support@navigatingcare.com</a>`
    const htmlMessage = `<div><p>${message} ${aTag} ${message1} </p></div>` 
    return {
      __html: htmlMessage
    }
  }

  const printConsentPath = () => { 
    window.open(`/print-unsigned-consent/${locale}`); 
  }

  return (
    <>
      <LogoHeader />
      <Flex
        pt={1}
        flexDirection="column"
        alignItems="left"
        justifyContent="spaceBetween"
      >
      </Flex>
      <Content px={25} pt={3}>
        { printing_enabled &&
          <Flex flexDirection="row" width="100%">
            <div style={{ width: '100%', textAlign: 'right' }}>
              <div onClick={printConsentPath} style={{ color: '#3B7C50', fontFamily: "'Open Sans', sans-serif", fontWeight: 400, fontSize: '16px', cursor: 'pointer' }}>
                Print 
                <Icon
                  variant="primary"
                  icon={faPrint}
                  size="sm"
                />
              </div>
            </div>
          </Flex>
        }

        <Flex flexDirection="row" width="100%">
          <Text as="span" textAlign="start">
            <AuthorizedIcon width="70" height="70" textAlign="center" />
          </Text>
          <Heading mb="0px" ml="20px" textAlign="left">
            { consent_content.title }
          </Heading>
        </Flex>

        <Flex flexDirection="column" padding="15px" mb="20px">
          <Flex flexDirection="column" backgroundColor="veryLightGreen" padding="15px" mb="20px">
          <Text mb={1} textStyle="normal" textAlign="left">
            { consent_content.pt_ed_subtext1 }
          </Text>
          <Text mb={1} textStyle="mediumHeading" textAlign="left">
            { consent_content.pt_ed_subtext2 }
          </Text>
          <Text mb={1} textStyle="normal" textAlign="left">
            { consent_content.pt_ed_subtext3 }
          </Text>
          </Flex>
          <Text mb={1} textStyle="normal" textAlign="left">
            { consent_content.clinic_can_provide_education }
          </Text>
          <Text mb={1} textStyle="normal" textAlign="left">
            { consent_content.these_materials_are_provided }
          </Text>
          <Text mb={1} textStyle="normal" textAlign="left">
            { consent_content.your_personal_information_will_not_be_shared1 }{' '}
            <a href="https://www.navigatingcare.com/privacy-policy">{ consent_content.privacy_policy }</a>
            {' '}{ consent_content.and }{' '}
            <a href="https://www.navigatingcare.com/terms-of-use">{ consent_content.terms_of_use }</a>
            {' '}{ consent_content.your_personal_information_will_not_be_shared2 }
          </Text>

          <List listStyle="inside disc" listStylePosition="outside">
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.by_participating_you_authorize }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.in_order_to_personalize }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.information_disclosed }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.clinic_will_not_condition_treatment }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.may_receive_compensation }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.you_may_opt_out1 }
              {' '}<a href="mailto:support@navigatingcare.com">support@navigatingcare.com</a>{' '}
              { consent_content.you_may_opt_out2 }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left">
              { consent_content.if_you_opt_out }
            </ListItem>
            <ListItem mb={1} textStyle="normal" textAlign="left"> 
              <div dangerouslySetInnerHTML={sanitizeHTML(consent_content.you_may_request_a_copy, consent_content.you_may_immediately_access)}></div>
            </ListItem>
          </List>
        </Flex>
      </Content>
      <Flex
        flexDirection="column"
        alignSelf="center"
        position="sticky"
        bottom="0px"
        marginLeft="-5px"
        paddingTop="20px"
        paddingLeft="25px"
        paddingRight="25px"
        backgroundColor="white"
        width="640px"
      >
        <Text
          textStyle="normal"
          textAlign="left"
          mb={3}
          mt={2}
          lineHeight={0}
          height={'0px'}
          marginTop={'0px'}
          marginBottom={'0px'}
        >
          { consent_content.i_agree_to_use }
        </Text>
        <PrimaryButton
          mb={3}
          onClick={onContinueButtonClick}
        >
          {t('patientConsent.agree')}
        </PrimaryButton>
        <LinkButton onClick={onDeclineClick}>
          {t('patientConsent.decline')}
        </LinkButton>
      </Flex>
      <Footer />
    </>
  )
}

import { getCurrentYear } from '../utils/getCurrentYear'

const year = getCurrentYear()
export const en = {
  welcome: {
    signIn: 'Sign In',
    signInText:
      'Please check your text or email messages for a secure link to access your account.',
    needHelp: 'Need Help?',
    contactClinic: 'Please contact your care team. For technical questions, contact the ',
    customerSupport: 'Navigating Care support team'
  },
  help: {
    helpTitle: 'Help',
    supportHours: 'Customer Support Hours',
    day: 'Monday – Friday',
    time: '7:00am – 5:00pm (PST)',
    phone: 'Phone',
    email: 'Email',
    FAQ: 'Frequently Asked Questions',
    question1: 'What is Navigating Care?',
    answer1:
      "Navigating Care is a service provided by your clinic that gives you secure access to your personal health information from any smartphone or web-browser device, such as a desktop computer or tablet. With Navigating Care, you'll now have an easy and convenient way to stay connected with your clinic outside of your regularly scheduled appointments.",
    question2: 'Why did I receive a text and/or email message?',
    answer2:
      'Your clinic has partnered with Navigating Care to send you notifications whenever new care information or resources become available for you. Simply click the link within your text or email message to securely access and view personalized information about your health or the care you received from your clinic.',
    question3: 'Is it safe to click the link in the message I received?',
    answer3:
      "Yes! Each link is completely safe and generated uniquely for you. Navigating Care abides by anti-spam requirements in compliance with the U.S. CAN-SPAM Act, meaning you'll never receive a message unless you've agreed to receive notifications from your clinic.",
    question4:
      'Is my personal health information (PHI) secure in Navigating Care?',
    answer4:
      "Your message link and all the information provided is secure and protected. Navigating Care is fully compliant with federal HIPAA and state laws pertaining to your privacy. To prevent any unauthorized access, we require you to verify your identity for each device on which you use Navigating Care - whether it's your phone, desktop, or tablet - before you can gain access to your information.",
    question5: 'Does the link expire?',
    answer5:
      'Yes. For your security, we require you to re-verify your identity every 90 days for each device on which you use Navigating Care. Once verified, you will be able to click on any link previously sent to you to access your information. However, if you choose to sign out of Navigating Care, you will be asked to re-verify your identity the next time you attempt to view your information.',
    question6:
      'What should I do if my personal health information is incorrect?',
    answer6:
      "Navigating Care receives your personal health information directly from your clinic's electronic medical record. If you believe there is an error, please contact your practice directly to update your information.",
    question7: 'Is there a fee to use the service?',
    answer7:
      'No, Navigating Care is a free service provided by your clinic to help you better manage your care. However, standard text messaging rates may apply if you choose to receive messages via your mobile device.',
    question8: 'Who do I contact for technical assistance?',
    answer8: {
      answer:
        'For technical assistance with Navigating Care, please contact the Navigating Care support team at',
      orCall: 'or call',
      time:
        "Monday through Friday, 7AM to 5PM Pacific Time. We'll do our best to help!"
    }
  },
  acceptTerms: {
    title: 'Almost there',
    reviewMessageTitle:
      'Please review the following information about receiving messages from {{clinicName}}.',
    chooseReceiveText:
      'If you choose to receive text messages from Navigating Care, standard carrier message and data rates may apply. For help, text HELP to 82790 or visit',
    moreInformation: 'For more information, view our',
    privacyPolicy: 'Privacy Policy,',
    orSee: 'or see',
    FAQ: 'Frequently Asked Questions',
    texingStop: 'You may opt out of text messages by texting STOP to 82790.',
    acceptingNC: 'By clicking Continue, you are accepting the Navigating Care',
    termsOfUse: 'Terms of Use',
    continue: 'Continue',
    decline: 'I Decline'
  },
  patientConsent: {
    agree: 'Yes, I agree',
    decline: 'I decline'
  },
  confirmSignOut: {
    title: "Are you sure you'd like to sign out?",
    subTitle:
      "Once signed out, you'll need to verify your identity again to view messages from your clinic.",
    staySignIn: 'Stay signed in',
    signOut: 'Sign Out'
  },
  editPreferences: {
    title: 'Preferences',
    mobileNo: 'Mobile Phone Number',
    emailAddress: 'Email Address',
    save: 'Save',
    cancel: 'Cancel'
  },
  listMessages: {
    title: 'Messages'
  },
  privacyPolicy: {
    title: 'Navigating Care Privacy Policy',
    privacyPolicy: {
      heading: 'Last updated January 26, 2022.',
      paragraphs: [
        'This online privacy policy describes the privacy practices for Navigating Cancer, Inc. (“Navigating Cancer” “our,” “we,” or “us”). Navigating Cancer considers the privacy and security of user information an important component of the Navigating Care service, including services offered on our websites, navigatingcare.com and nav.care (the “Sites”), email and text communications.   Navigating Cancer is committed to the privacy and confidentiality of your personal information. The following information explains how Navigating Cancer collects and uses information obtained from its users in connection with our services (collectively, the “Services”).',
        'By accessing the Sites or using the Services, you agree to the terms of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the Sites or Services. '
      ]
    },
    extraSpace0: {
      heading: ''
    },
    coverage: {
      heading: 'What this Policy Covers.',
      paragraphs: [
        'This Privacy Policy covers the personal information collected from you when you are on the Sites and when you use the Services and Navigating Cancer’s treatment of such personal information. Also, this Privacy Policy covers Navigating Cancer’s treatment of personal information that Navigating Cancer’s business partners and healthcare providers share with Navigating Cancer. This Privacy Policy does not apply to the practices of companies that Navigating Cancer does not own or control, or to individuals that Navigating Cancer does not employ or manage.'
      ]
    },
    extraSpace1: {
      heading: ''
    },
    hippa: {
      heading: 'HIPAA Notice of Privacy Practices.',
      paragraphs: [
        "To access your healthcare provider's HIPAA Notice of Privacy Practices please visit your healthcare provider's website."
      ]
    },
    extraSpace2: {
      heading: ''
    },
    personalInfo: {
      heading: 'Personal Information Collected and How We Use It.',
      paragraphs: [
        'In order to provide users with a friendly, customized, and efficient experience, we collect personal information of several types. We also collect personal information received from your healthcare provider in order to provide Services to you and to your healthcare provider including but not limited to care management, health tracker, and patient portal solutions.  We collect personal information from your device or browser, and directly from you.  We may use personal information we collect in order to provide you with our Services, to address your questions, comments or requests, to develop and analyze our products and Services, to personalize your experience with our Services, comply with out legal and contractual obligations, and protect our rights, property, and safety (or the rights, property and safety of others).  We have collected these categories of information within the last twelve months.'
      ]
    },
    providedInfo: {
      heading: 'A) Information You Provide to Us:',
      paragraphs: [
        'We may receive and store information you enter on the Sites or otherwise provide to us through the Services, including information that we receive as a result of our back-office integration with your healthcare provider’s clinical systems.',
        '1. Registration. In order for you to use certain features of the Services, you may be required to complete a registration form which may ask for personal information.',
        '2. Health Records. We work with your healthcare providers to provide you the Services.  Your healthcare providers share personal information about you and your treatment with us, in order for us to provide you with the Services, and we share with your healthcare provider information that you input into the Sites (e.g., secure messaging).  We recognize the sensitivity of your personal information and we will protect any information provided by your healthcare providers to us as required by HIPAA.  As a user of Navigating Care, you can access and manage your health records and monitor and record your daily health information and disease symptoms and/or side effects. All of the health record pages on Navigating Care use Secure Sockets Layer (SSL) encryption to protect your personal information. Examples of the types of information stored in your Navigating Care health records include (this is not an exclusive list):',
        [
          'General demographic personal information including, but not limited to, your name, address, date of birth, gender and contact information',
          'Diagnosis',
          'Treatments including surgeries, radiation treatments, chemotherapy, complementary or alternative medicines',
          'Lab results and clinical notes',
          'Prescription medications, dietary supplements, herbal remedies, or other medications',
          'Healthcare Provider team member’s contact information such as your doctor’s name, specialty, address, email and phone numbers',
          'Clinic facility information, such as names, addresses and phone numbers',
          'Daily Health Tracker information, including self-reported details about your general health and wellbeing, as well as self-reported disease symptoms and/or side effects of treatment',
          'Medical appointment dates, times and physician information',
          'Secure messaging with your healthcare providers'
        ],
        '3. Your Communications with Us.  We collect personal information from you such as email address, date of birth, zip code, phone number, or mailing address when you request information about our Services, sign up for communications, request customer or technical support, apply for a job or otherwise communicate with us.',
        '4. Surveys.  We may contact you to participate in surveys.  If you decide to participate, you may be asked to provide certain information which may include personal information.'
      ]
    },
    automaticInfo: {
      heading: 'B) Automatic Information:',
      paragraphs: [
        'We may receive and store certain types of information whenever you interact with the Sites or Services. Navigating Cancer may automatically receive and record certain "traffic data" on our server logs from your browser including your Internet Protocol (IP) address, geo-location, search history, browsing history, the uniform resource locator (URL), the website you linked from, Navigating Cancer cookie information, and the page you requested.',
        'In addition, we may automatically collect data regarding your use of our Services, such as the types of content you interact with and the frequency and duration of your activities.  We may combine your information with information that other people provide when they use our Services, including information about you when they tag you.  We may also use Google Analytics and other service providers to collect information regarding visitor behavior and visitor demographics on our Services.'
      ]
    },
    emailCommunications: {
      heading: 'C) E-mail and SMS Communications:',
      paragraphs: [
        'We may communicate with you via email and text messaging (SMS), for example for registration purposes, to provide educational materials, and to notify you when your clinic has updated your patient portal.  We may also send out emails with news or promotions. If you do not want to receive e-mail or other mail from us, please notify us by e-mail at unsubscribe@navigatingcare.com and include sufficient information for us to identify your account.'
      ]
    },
    otherInfo: {
      heading: 'D) Information from Other Sources:',
      paragraphs: [
        'In order for us to develop a more personalized service offering, we may receive information about you from other sources and add it to our account information.'
      ]
    },
    extraSpacee3: {
      heading: ''
    },
    sharingInfo: {
      heading: 'Sharing and Use of Information.',
      paragraphs: [
        'We do not sell any personally identifiable information to anyone without your consent, and within the last twelve months we have shared your information consistent with the statements below. We will not share your personal information (described above) except in the following circumstances:'
      ]
    },
    consent: {
      heading: 'A) Consent:',
      paragraphs: [
        'Navigating Cancer may disclose personal information when you have provided consent to do so.'
      ]
    },
    consistentWithHippa: {
      heading: 'B) Consistent with HIPAA:',
      paragraphs: [
        'Navigating Cancer may use and disclose personal information if it is required to do so by law, when it is permitted to do so consistent with HIPAA, or if Navigating Cancer has a good faith belief that such action is necessary to conform to applicable laws or comply with any legal, regulatory or similar requirement or investigation, to protect or defend the rights or property of Navigating Cancer or another user or to enforce Navigating Cancer’s Terms of Use.'
      ]
    },
    businessTransfers: {
      heading: 'C) Business Transfers:',
      paragraphs: [
        'Navigating Cancer may transfer your personal information to any successor to all or substantially all of its business or assets that concern the Services.'
      ]
    },
    govtEntities: {
      heading: 'D) Government Entities/Security:',
      paragraphs: [
        'We may share personal information in response to a court order, subpoena, search warrant, law or regulation. We may also use personal information to comply with our legal, regulatory, security and risk management obligations, including defending legal claims.  We may cooperate with law enforcement authorities in investigating and prosecuting activities that are illegal, violate our rules, or may be harmful to other visitors.  We may share personal information, to address fraud, security or other technical issues.  We may also share personal information to protect against harm to Navigating Cancer or other third parties.'
      ]
    },
    serviceProviders: {
      heading: 'E) Service Providers:',
      paragraphs: [
        'We may share any personal information we collect about you with our third-party service providers.  The categories of service providers to whom we entrust personal information include IT and related services; information and services; services such as Google Analytics, customer service providers; and vendors to support the provision of the Services.'
      ]
    },
    internationalData: {
      heading: 'F) International Data Transfers:',
      paragraphs: [
        'You agree that all information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live.  We have taken appropriate safeguards to require that your personal information will remain protected and require our third-party service providers and partners to have appropriate safeguards as well.'
      ]
    },
    thirdParty: {
      heading: 'G) Information You Share with Third Parties:',
      paragraphs: [
        'You should be aware that by providing third parties with your name (User ID) and password, you may inadvertently enable unauthorized persons to review, modify or delete your health records. If you provide a third party with your user credentials, Navigating Cancer cannot monitor their use of the Site or Services.'
      ]
    },
    publicInfo: {
      heading: 'H) Information You Share With the Public:',
      paragraphs: [
        'The Sites will allow you to post information into a public forum. If you post personal information into a public forum, the public will have access to such information. Please note, once you make a public posting through the Sites, you may not be able to change or remove it.',
        'THIS PRIVACY POLICY DOES NOT PROTECT YOU WHEN YOU USE OUR PUBLIC FORUMS OR PROVIDE INFORMATION (INCLUDING PERSONALLY IDENTIFIABLE INFORMATION) ABOUT YOURSELF THROUGH THESE PUBLIC FORUMS.',
        'You should be aware that any information shared in a public forum such as a message board, posting, group, bulletin board, review posting or through Navigating Cancer public forums is public information and may be seen or collected by third parties that do not adhere to our Privacy Policy. You should think carefully before disclosing any information in any public forum. For example, you should not select a username that includes personally identifiable information.'
      ]
    },
    extraSpace4: {
      heading: ''
    },
    aggregation: {
      heading: 'Aggregation of De-identified Information and Statistics.',
      paragraphs: [
        'Navigating Cancer may de-identify your personal information, including your healthcare information.  Navigating Cancer and its partners may use and disclose de-identified and or aggregated information gathered from your profile and your usage of the Sites and related statistics.   If we are de-identifying personal healthcare information then, such de-identification will be in compliance with HIPAA requirements and such de-identified information will not include personally identifiable information relating to users such as name, email address, or address, but may include gender, height, weight, postal code, and medical information such as diagnosis and stage of illness. We will not attempt to re-identify the de-identified information, except we may attempt to re-identify the information solely for the purpose of determining whether our de-identification processes satisfy legal requirements.  Aggregated information is information from multiple users that cannot be “re-identified,” or associated with any individual user or account.  This de-identified data may also include data extracted on an anonymous basis from the health care information we receive from your physician or other care providers once you have agreed to the use of our Services.  We will require any of our partners that receive de-identified data from us to agree to not re-identify the data.'
      ]
    },
    extraSpace5: {
      heading: ''
    },
    security: {
      heading: 'Security.',
      paragraphs: [
        'Your Navigating Care account information is protected by a password for your privacy and security. You are responsible to protect against unauthorized access to your account and to your computer by logging off once you have finished using a shared computer, and to your mobile phone by enabling your device’s security features.',
        'It is our policy to protect the confidentiality of Social Security numbers ("SSNs”) that we receive or collect in the course of business. We secure the confidentiality of SSNs through various means, including physical, technical, and administrative safeguards that are designed to protect against unauthorized access. It is our policy to limit access to SSNs to that which is lawful, and to prohibit unlawful disclosure of SSNs.',
        '“Do Not Track.”  Do Not Track (“DNT”) is a preference you can set in your web browser to let the websites you visit know that you do not want them collecting data about you. At this time, this Site does not respond to DNT or similar signals.'
      ]
    },
    extraSpace6: {
      heading: ''
    },
    thirdPartySites: {
      heading: 'Third Party Sites.',
      paragraphs: [
        'The Services may contain links to other websites, and other websites may reference or link to our website or other Services.  These other websites are not controlled by us.  We encourage our users to read the privacy policies of each website and application with which they interact.  We do not endorse, screen or approve and are not responsible for the privacy practices or content of such other websites or applications.  Visiting these other websites or applications is at your own risk.'
      ]
    },
    extraSpace7: {
      heading: ''
    },
    cookies: {
      heading: 'Cookies',
      paragraphs: [
        'Cookies are alphanumeric identifiers that we may transfer to your computer’s hard drive through your Web browser to enable our systems to recognize your browser and tell us how and when pages in our Sites are visited and by how many people. Navigating Care cookies do not collect personal information, and we do not combine information collected through cookies with other personal information to tell us who you are or what your email address is.',
        'Your internet browser may direct you on how to prevent your browser from accepting new cookies, how to command the browser to tell you when you receive a new cookie, or how to fully disable cookies. We strongly recommend that you leave the cookies activated, because cookies enable you to take advantage of some of the Services’ most attractive features.'
      ]
    },
    extraSpace8: {
      heading: ''
    },
    minors: {
      heading: 'Minors.',
      paragraphs: [
        'The Services are not directed to children under 18, and we do not knowingly collect or sell personal information from children.  However, if requested by your healthcare provider, minor patients, parents or guardians may elect to establish a portal account, and, in doing so, expressly consent to Navigating Cancer utilizing such information as set forth in this Privacy Policy and the Terms of Use.  If you learn that your child has provided us with personal information without your consent, you may contact us as set forth below.  If we learn that we have collected any personal information in violation of applicable law, we will promptly take steps to delete such information and terminate the child’s account.'
      ]
    },
    extraSpace9: {
      heading: ''
    },
    changes: {
      heading: 'Changes.',
      paragraphs: [
        'Navigating Cancer reserves the right to change or update this Privacy Policy at any time by posting a notice at the Sites. Changes to the Privacy Policy will take effect immediately unless otherwise noted. Your continued access of the Services constitutes your agreement to this Privacy Policy and any updates or revisions.'
      ]
    },
    extraSpace10: {
      heading: ''
    },
    contact: {
      heading: 'Contact.',
      paragraphs: [
        'If you would like to make changes to your personal health information, please contact your healthcare provider directly.  If you have any questions regarding this Privacy Policy or your dealings at this Sites, please contact Navigating Cancer at:',
        'Attn: Privacy Officer',
        'Re: Privacy Policy',
        '1008 Western Avenue Suite 206',
        'Seattle, Washington 98104',
        'support@navigatingcare.com'
      ]
    },
    extraSpace11: {
      heading: ''
    },
    specialForCali: {
      heading: 'Special Notice for California Residents:',
      paragraphs: [
        'In addition to the Privacy Policy above, California residents may be entitled to the following privacy rights under California law:',
        'A) The right to know about personal information collected, disclosed or sold.  You have the right to request that we disclose what personal information we collect, use, disclose and sell.  Please note that we describe these practices above in our Privacy Policy.',
        'B) The right to request deletion of personal information.  You, or your authorized agent, have the right to request that we delete the Personal Information that we have collected about you.  Subject to HIPAA, other laws, and our legal obligations (including contractual obligations) we may deny your request.  There may be exceptions under California law that may allow Navigating Cancer to maintain and use your personal information notwithstanding your request for deletion.  We will inform you of our reasons for denying your request.',
        'C) The right to opt-out of the sale of personal information.  Under California law, you have the right to opt-out of the sale of personal information; however, Navigating Cancer does not sell your personal information.',
        'D) The right to non-discrimintation for the exercise of a consumer’s privacy rights.  You have the right to not receive discriminatory treatment by Navigating Cancer for the exercise of your privacy rights conferred by California law.  Please note that if you exercise certain rights you may not be able to use or access all of Navigating Cancer’s Services.',
        'E) Submission of requests.  You may submit requests by contacting support@navigatingcancer.com.  We may request identity verification before processing your requests (e.g. ensuring that your information matches the identifying information that we have within our system) and we will not process your request without sufficient information to reasonably verify your identity, or the identity of your authorized agent and their authority to submit this request.',
        'F) The California “Shine the Light” law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed your personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties.'
      ]
    }
  },
  start: {
    helthInformation: 'Your health information provided by',
    welcome: 'Welcome',
    verifyInformation:
      'Verify your information to access messages from your clinic.',
    start: 'Start',
    privacyInformation:
      'Your privacy is important to us. For more information please review our',
    termsOfUse: 'Terms of Use',
    and: 'and',
    privactPolicy: 'Privacy Policy'
  },
  termsAndConditions: {
    title: 'Terms of Use',
    contactHeading: 'Contact.',
    contactNC: 'You may contact Navigating Cancer at the following address:',
    lastModified: 'Last Modified: October 15, 2020.',
    acceptanceOfTerms: {
      heading: 'Acceptance of Terms.',
      paragraphs: [
        'The following terms and conditions govern all use of the Navigating Care™ service, including the navigatingcare.com, navigatingcancer.com, and nav.care websites (the "Sites"), and the services available at the Sites and from Navigating Care (the "Service"). The Service is owned and operated by Navigating Cancer, Inc. ("Navigating Cancer"). The Service is offered subject to acceptance without modification of all of the terms and conditions contained herein, and all other operating rules, policies, and procedures that may be published from time to time on the Sites by Navigating Cancer (collectively, the "Terms of Use").',
        'The Service is available only to individuals who are at least 18 years old. If you do not so qualify, do not attempt to register for or use the Service. Navigating Cancer may refuse to offer the Service to any person or entity and may change its eligibility criteria, at any time, in its sole discretion.',
        'Please take a few minutes to read the Terms of Use carefully. The information provided by the Service is designed to support, not replace, the relationship between patient/site visitors and their healthcare providers.',
        'IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF USE, THEN DO NOT ACCESS OR USE THE SERVICE. BY VIEWING OR USING ALL OR ANY PART OF THE SERVICE, DOWNLOADING OR ACCESSING ANY MATERIALS OR BY COMPLETING THE REGISTRATION PROCESS, YOU AGREE TO BE BOUND BY ALL OF THESE TERMS OF USE.'
      ]
    },
    changes: {
      heading: 'Changes.',
      paragraphs: [
        'Navigating Cancer reserves the right, at its sole discretion, to modify or replace any of these Terms of Use at any time without notice to you. All changes are effective immediately when we post them. It is your responsibility to check the Terms of Use periodically for changes. Your continued use of the Service following the posting of any changes to the Terms of Use constitutes acceptance of those changes.'
      ]
    },
    registration: {
      heading: 'Registration.',
      paragraphs: [
        'As a condition to using the Service, you may be required to register with Navigating Care and select a password and name (User ID). You shall provide Navigating Care with accurate, complete, and updated registration information. Failure to do so shall constitute a breach of the Terms of Use, which may result in immediate termination of your account. You must ensure that your account with Navigating Care contains (at all times) either a valid email address and/or a valid mobile phone number. In addition, if you elect to create a User ID and password for accessing the Sites:',
        [
          'you shall not (i) select or use as a User ID a name of another person with the intent to impersonate that person; (ii) use as a User ID a name subject to any rights of a person other than you without appropriate authorization; or (iii) use as a User ID a name that is otherwise offensive, vulgar, or obscene;',
          'Navigating Cancer reserves the right to refuse registration of or cancel a User ID in its sole discretion;',
          'you are solely responsible for activity that occurs on your account and shall be responsible for maintaining the confidentiality of your password;',
          "you shall never use another user's account without such other user's express permission, unless you are a designated personal representative of the user;",
          'you shall never transfer or assign your account to any third party or provide a third party with your login and password;',
          'you will immediately notify Navigating Cancer in writing of any unauthorized use of your account, or other account related security breach of which you are aware;',
          'you shall only have one (1) account with the Service (which, for clarity, means that you will not register (or attempt to register) for more than one (1) account); provided that, within such personal account with the Service you may be able to open sub-accounts for another user when acting as their personal representative.'
        ]
      ]
    },
    privacy: {
      heading: 'Privacy.',
      paragraphs: [
        'Our policies pertaining to the usage of our Service are contained in our <a href="/privacy">Privacy Policy</a> and are incorporated into these Terms of Use by this reference.'
      ]
    },
    noMedicalAdvice: {
      heading: 'No Medical Advice.',
      paragraphs: [
        'This Service Does Not Provide Medical Advice.',
        'All of the material provided by the Service, such as text, treatments, dosages, outcomes, charts, member profiles, graphics, images, advice, messages, forum postings, and any other material provided by the Service are for informational and educational purposes only and are not a substitute for professional medical advice, treatment, or your own judgment. We encourage you to be careful when using medical information, and to always seek the advice of your physician or other qualified health provider with any questions you may have regarding your health and based on your particular circumstances. Content provided on the Sites may not have been reviewed by your physician or other healthcare provider; you should always consult with your provider for an understanding and interpretation of any test results, clinical notes or other material that may be included on the Sites. Never disregard professional medical advice or delay in seeking it because of something you have read from the Service. We do not guarantee or take responsibility for the medical accuracy of the content provided on the Sites, nor can we assume any liability for the content of web sites linked to our site.',
        'Neither Navigating Cancer nor its affiliates, directors, officers, employees, or contractors (collectively, the "Affiliates") recommends or endorses any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned by the Service. Reliance on any information provided by Navigating Cancer or its Affiliates, by persons appearing on the Sites at the invitation of Navigating Cancer, or by other members is solely at your own risk.'
      ]
    },
    notForEmergencies: {
      heading: 'Not for Emergencies.',
      paragraphs: [
        'If you think you may have a medical emergency, call your physician or 911 immediately. The Service is for non-emergency purposes only. Do not attempt to access emergency care through the Service or the Sites. The Service is not intended to support emergency or time-critical calls or communications to any type of hospital, medical care facility, law enforcement agency or any other kind of emergency or time-critical care service.',
        'Navigating Cancer, its affiliates or staff, are in no way liable for such emergency calls or communications. You may not rely on the electronic communications for assistance with your urgent medical care needs. Any email or information submission provided on the Sites is not designed to facilitate medical care or emergency medical services.'
      ]
    },
    rulesOfConduct: {
      heading: 'Rules and Conduct.',
      paragraphs: [
        'The Service (including without limitation, any content, documents, or other information made available via the Service) is provided only for your own informational purposes. Any unauthorized use of the Service, including without limitation, any commercial use (such as, for example, resale by you or your representatives to third parties), is expressly prohibited.',
        'As a condition of use, you promise not to use the Service for any purpose that is unlawful or prohibited by these Terms of Use, or any other purpose not reasonably intended by Navigating Cancer. You further agree to abide by all applicable local, state, national, and international laws and regulations. Use of the Service outside of the United States shall be in compliance with the laws of the jurisdiction from which you access the Service.',
        'By way of example, and not as a limitation, you agree not to (a) take any action or (b) upload, post, submit, or otherwise distribute or facilitate distribution of any content (including text, communications, software, images, sounds, data, or other information) using any communications service or other service available on or through the Service, that:',
        [
          "is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy, tortious, obscene, or pornographic, profane, or which otherwise violates the Terms of Use;",
          'infringes any patent, trademark, trade secret, copyright, right of publicity, or other right of any party;',
          'constitutes unauthorized or unsolicited advertising, junk, or bulk e-mail ("spamming");',
          "imposes an unreasonable or disproportionately large load on Navigating Cancer's computing, storage, or communications infrastructure, or attempts to gain unauthorized access to the Service, other accounts, computer systems, or networks connected to the Service, through password mining or otherwise;",
          'contains software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit, or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, or other information of Navigating Cancer or any third party; or',
          'harvests or collects any information from the Service; or',
          'impersonates any person or entity, including Navigating Cancer, or any employee or representative of Navigating Cancer.'
        ],
        'Navigating Cancer may, at its sole discretion, immediately terminate your access to the Service should your conduct fail to conform strictly with any provision of this section.'
      ]
    },
    content: {
      heading: 'Content.',
      paragraphs: [
        'Navigating Cancer has no obligation to monitor the Service, Sites, or any use thereof. However, Navigating Cancer reserves the right at all times and without notice to monitor, review, retain, or disclose any information as necessary to satisfy any applicable law, regulation, legal process, or governmental (including law enforcement) request.'
      ]
    },
    thirdPartySites: {
      heading: 'Third-Party Sites.',
      paragraphs: [
        "The Service may permit you to link to other websites on the Internet, and other websites may contain links to the Sites. These other websites are not under Navigating Cancer's control, and you acknowledge that Navigating Cancer is not responsible for the accuracy, legality, appropriateness, or any other aspect of the content or function of such websites. The inclusion of any such link does not imply endorsement by Navigating Cancer or any association with its operators."
      ]
    },
    thirdPartyContent: {
      heading: 'Third-Party Content.',
      paragraphs: [
        'The Service makes information of third parties available, including articles, news reports, calculation tools, marketing materials from pharmaceutical manufacturers, and company information and data about cancer care, including "patient monographs" and other data specific to the treatment of patients whose identity has not been divulged (the "Third-Party Content"). You acknowledge and agree that the Third-Party Content is not created or endorsed by Navigating Cancer, nor any business offering products or services through it. The provision of Third-Party Content is for general informational purposes only and does not constitute a recommendation or inducement for the referral of patients or the purchase or order of any item or service. In addition, the Third-Party Content is not intended to constitute or provide medical advice. You acknowledge that the Third-Party Content provided to Navigating Cancer is obtained from sources believed to be reliable and that no guarantees are made by the Service or the providers of the Third-Party Content as to its accuracy, completeness, or timeliness. You agree not to hold Navigating Cancer, its Affiliates, the Service, any business offering products or services through the Service, or any provider of Third Party Content liable for any medical treatment decision or other transaction you may make based on your reliance on or use of such data, or any liability that may arise due to delays or interruptions in the delivery of the Third Party Content for any reason. If you decide to access any of the Third-Party Content, then you do so at your own risk and subject to the terms and conditions of use such content. You further agree to comply fully with all applicable laws in your dealings with respect to Third-Party Content.'
      ]
    },
    clinicIntegration: {
      heading: 'Clinic Integration.',
      paragraphs: [
        'If you are using this Service in conjunction with your physician\'s practice or clinic, you acknowledge and agree to permit certain information including, but not limited to your medical diagnosis, treatments, medications, and schedules, to be integrated with your account on the Service (the "Clinic Content"). The delivery to you of the Clinic Content is intended to provide you with accurate, timely, and relevant information. All Clinic Content is provided to you on an "as is" and on an "as available" basis and Navigating Cancer makes no assurance to you that the Clinic Content is accurate and correct. Like the remainder of the information available from the Service, the Clinic Content is subject to the terms of the Privacy Policy referenced above and available at http://www.navigatingcare.com/privacy-policy.',
        'THERE IS NO WARRANTY OF MERCHANTABILITY, NO WARRANTY OF FITNESS FOR A PARTICULAR USE, NO WARRANTY OF TITLE, AND NO WARRANTY OF NON-INFRINGEMENT. THERE IS NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THIRD PARTY CONTENT AND CLINIC CONTENT.'
      ]
    },
    thirdPartyTrademarksAndCopyrights: {
      heading: 'Third-Party Trademarks and Copyrights.',
      paragraphs: [
        'Third-Party Content is the property of its respective provider or its licensor and is protected by applicable copyright law as set forth below. You agree not to reproduce, retransmit, disseminate, sell, distribute, publish, broadcast, circulate, or commercially exploit the Third-Party Content in any manner without the express written consent of the Third-Party Content Provider.'
      ]
    },
    proprietaryRights: {
      heading: 'Proprietary Rights.',
      paragraphs: [
        'You agree that all content and materials delivered via the Service or otherwise made available by Navigating Cancer at the Sites including without limitation, all information, software, site design, text, displays, graphics, logos, buttons, icons, images, audio or video clips, service marks brand names, trade dress and trade names and the design, selection, and arrangement thereof (hereafter "Navigating Cancer Content"), are protected by United States and international copyrights, trademarks, service marks, patents, trade secrets, or other proprietary rights and laws. Navigating Cancer retains all right, title and interest in the Navigating Cancer Content and grants you no right, title or interest in any intellectual property owned or licensed by Navigating Cancer, including service marks, logos, brand names, trade dress and trade names ("Trademarks"). Except as expressly authorized by Navigating Cancer in writing, you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit, or create derivative works from Navigating Cancer Content and Trademarks. However, you may print or download a reasonable number of copies of the materials or content from the Service for your own informational purposes, provided that you retain all copyright and other proprietary notices contained therein. Systematic retrieval of data or other content from the Service to create or compile, directly or indirectly, a collection, database, or directory without written permission from Navigating Cancer is prohibited.',
        'You have no rights in or to such Navigating Cancer Content or Trademarks. Reproducing, displaying, copying, or distributing any Navigating Cancer Content or Trademarks from the Service for any other purpose is strictly prohibited without the express prior written permission of Navigating Cancer. Use of the Navigating Cancer Content or Trademarks for any purpose not expressly permitted in these Terms of Use is prohibited. Allowing anyone else to use the Navigating Cancer Content or Trademarks for any purpose not expressly permitted in these Terms of Use is prohibited. Use of Navigating Cancer Content or Trademarks in connection with any product or service that does not belong to Navigating Cancer, in any manner that is likely to cause confusion, or in any manner that disparages or discredits Navigating Cancer is strictly prohibited without express prior written permission of Navigating Cancer. Any rights not expressly granted herein are reserved.'
      ]
    },
    licenseToNavigatingCare: {
      heading: 'License to Navigating Care.',
      paragraphs: [
        'Navigating Cancer has a separate arrangement with your medical provider in relation to the Clinic Content. The following relates to content other than the Clinic Content ("Non-Clinic Content"), such as the content that you provide directly to the Sites or the Service.',
        'By uploading, submitting, or otherwise disclosing or distributing content of any kind at the Sites or otherwise through the Service, you:',
        [
          'grant to Navigating Cancer, its affiliates and their assignees a worldwide, perpetual, irrevocable, non-exclusive, transferable royalty-free license to access, use, reproduce, display, perform, adapt, modify, distribute, make derivative works of, and otherwise exploit such Non-Clinic Content in any form and for any purpose, including without limitation, any concepts, ideas, or know-how embodied therein;',
          'represent and warrant to Navigating Cancer that you own or otherwise control all rights to such Non-Clinic Content and that disclosure and use of such content by Navigating Cancer (including without limitation, publishing content through the Service) will not infringe or violate the rights of any third party; and',
          'acknowledge that the Non-Clinic Content may not be treated confidentially.'
        ],
        'Some areas of the Sites or Service may allow you to submit information and other content relating to your health or otherwise. While some such areas are monitored periodically for topicality, Navigating Cancer has no obligation to prescreen postings and is not responsible for their content. Navigating Cancer encourages you to notify us of inappropriate or illegal content, and Navigating Cancer reserves the right to remove postings for any reason.',
        'You agree not to provide Navigating Cancer with any confidential or proprietary information that you desire or are required to keep secret.',
        'THIS SECTION SHALL NOT APPLY TO THE EXTENT IT CONFLICTS WITH THE PRIVACY POLICY.'
      ]
    },
    termination: {
      heading: 'Termination.',
      paragraphs: [
        'If you wish to terminate your account, you may simply discontinue using the Service. Navigating Cancer may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately. Without limiting the foregoing, the following may lead to a termination by Navigating Cancer of your membership and use of the Service: (a) breaches or violations of this Agreement or other incorporated agreements or guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you (self-initiated membership cancellation), (d) unexpected technical issues or problems, and (e) extended periods of inactivity. Navigating Cancer has no obligation to maintain, store, or transfer to you, information or data that you have posted on or uploaded to the Service. Navigating Cancer will not be liable to you or any third party for termination of your access to the Service.'
      ]
    },
    disclaimerOfWarranties: {
      heading: 'Disclaimer of Warranties.',
      paragraphs: [
        'THE SERVICE (AND ALL MATERIALS, INFORMATION, PRODUCTS AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITES (COLLECTIVELY, THE CONTENT)) ARE PROVIDED "AS IS" AND "AS AVAILABLE". THE SERVICE AND CONTENT ARE PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. THIS DOES NOT AFFECT THOSE WARRANTIES THAT ARE NOT SUBJECT TO EXCLUSION, RESTRICTION, OR MODIFICATION UNDER THE LAWS APPLICABLE TO THE TERMS OF USE.',
        'NAVIGATING CANCER, AND ITS AFFILIATES, LICENSORS, AND SUPPLIERS DO NOT WARRANT THAT: (A) THE CONTENT IS TIMELY, ACCURATE, ADEQUATE, COMPLETE, RELIABLE, USEFUL OR CORRECT; (B) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (C) ANY DEFECTS, ERRORS, OR OMISSIONS WILL BE CORRECTED; (D) THE CONTENT IS FREE OF VIRUSES, WORMS, OR OTHER HARMFUL COMPONENTS; OR (E) THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS.',
        'YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK. THE CONTENT IS MADE AVAILABLE FOR EDUCATIONAL PURPOSES ONLY AND DOES NOT CONSTITUTE PROFESSIONAL ADVICE. PROFESSIONAL MEDICAL AND HEALTHCARE CONSULTATION DEPENDS ON THE PRECISE FACTS OF EACH AND EVERY INDIVIDUAL CASE. THE CONTENT IS IN NO WAY A SUBSTITUTE FOR ANY SUCH PROFESSIONAL CONSULTATION. ALWAYS CONSULT YOUR PHYSICIAN OR OTHER RELEVANT AND QUALIFIED HEALTHCARE PROVIDERS FOR DIAGNOSTIC AND TREATMENT PURPOSES. WE DO NOT ENDORSE, OR RECOMMEND, OR IN ANY MANNER ASSUME RESPONSIBILITY FOR, ANY CONTENT.',
        'SOME STATES DO NOT ALLOW CERTAIN WARRANTY LIMITATIONS, SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.'
      ]
    },
    limitationOfLiability: {
      heading: 'Limitation of Liability.',
      paragraphs: [
        'IN NO EVENT SHALL NAVIGATING CANCER (OR ITS AFFILIATES, LICENSORS, OR SUPPLIERS) BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, GOODWILL, OR OTHER INTANGIBLE LOSSES OF ANY KIND ARISING OUT OF OR CONCERNING THE SUBJECT MATTER OF THIS AGREEMENT, REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR (A) YOUR USE OF OR INABILITY TO USE THE SERVICE OR THE CONTENT CONTAINED THEREIN, (B) ANY MATTER BEYOND ITS REASONABLE CONTROL, OR (C) ANY LOSS OR INACCURACY OF DATA, ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, LOSS OR INTERRUPTION OF USE, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE, OR COST OF PROCURING SUBSTITUTE CONTENT OR SERVICES, EVEN IF NAVIGATING CANCER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY REMEDY PROVIDED HEREIN. SOME STATES DO NOT ALLOW THE LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.'
      ]
    },
    indemnification: {
      heading: 'Indemnification.',
      paragraphs: [
        "You agree to defend, indemnify, and hold harmless Navigating Cancer, its affiliates and their employees, contractors, officers, directors, and representatives from all liabilities, claims, and expenses, including attorneys' fees, that arise from your use or misuse of the Service or your violation of these Terms of Use or other contract between you and Navigating Cancer. Navigating Cancer reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Navigating Cancer in asserting any available defenses."
      ]
    },
    internationalUse: {
      heading: 'International Use.',
      paragraphs: [
        'Navigating Cancer makes no representation that the Content is appropriate or available for use in locations outside the United States, and accessing the Service is prohibited from territories where such Content is illegal. If you access the Service from other locations, you do so at your own initiative and are responsible for compliance with local laws.'
      ]
    },
    disputeResolution: {
      heading: 'Dispute Resolution.',
      paragraphs: [
        'A printed version of these Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. You and Navigating Cancer agree that any cause of action arising out of or related to the Service must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred. You agree that you will notify Navigating Cancer in writing of any claim or dispute concerning or relating to your use of the Sites or Service and give Navigating Cancer a reasonable period of time to address it before bringing any legal action, either individually or as a class member against Navigating Cancer.',
        'These Terms of Use shall be governed by and construed in accordance with the laws of the State of Washington, without regard to its conflicts of law rules, and the United States of America. You expressly agree that the exclusive jurisdiction for any claim or action arising out of or relating to these Terms of Use or your use of the Sites or Service shall be filed only in the state or federal courts located in Washington, USA, and you further agree and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim or action. Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions of these Terms of Use, including without limitation, this section.'
      ]
    },
    integrationAndSeverability: {
      heading: 'Integration and Severability.',
      paragraphs: [
        'These Terms of Use are the entire agreement between you and Navigating Cancer with respect to the Service and use of the Sites, and supersede all prior or contemporaneous communications and proposals (whether oral, written, or electronic) between you and Navigating Cancer with respect to the Service (but excluding the use of any software which may be subject to a separate end-user license agreement). If any provision of the Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms of Use will otherwise remain in full force and effect and enforceable.'
      ]
    },
    miscellaneous: {
      heading: 'Miscellaneous.',
      paragraphs: [
        "These Terms of Use and the Service contemplated hereunder are personal to you, and are not assignable, transferable, or sublicensable by you except with Navigating Cancer's prior written consent. Navigating Cancer may assign, transfer, or delegate any of its rights and obligations hereunder without your consent. All waivers and modifications must be in writing signed by both parties, except as otherwise provided herein. No agency, partnership, joint venture, or employment relationship is created as a result of this Agreement, and neither party has any authority of any kind to bind the other in any respect. In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys' fees. All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day delivery by recognized overnight delivery service."
      ]
    },
    copyrightAndTrademarkNotices: {
      heading: 'Copyright and Trademark Notices.',
      paragraphs: [
        `Unless otherwise indicated, these Terms of Use and all Content provided by Navigating Cancer are copyright &copy; 2008-${year} Navigating Cancer.com, Inc. All rights reserved.`,
        '"Navigating Cancer," "Navigating Care," as well as page headers, custom graphics, buttons, images, and other content on the Sites, are subject to trademark, service mark, trade dress, copyright, and or other proprietary or intellectual property rights or licenses held by Navigating Cancer, Inc. or its licensors, supplier, or partners. Other trademarks, product names, and company names or logos used on the Sites are the property of their respective owners. Except as expressly authorized, the use or misuse of any trademarks, trade names, logos, images, graphics, or content from the Sites is strictly prohibited.'
      ]
    },
    contact: {
      heading: 'Contact',
      paragraphs: [
        'You may contact Navigating Cancer at the following address: <a href="mailto:info@navigatingcancer.com">info@navigatingcancer.com</a>'
      ]
    }
  },
  termsDeclined: {
    thankYou: 'Thank you',
    subTitle:
      "We will no longer send alerts about this message. If you decide you'd like to access your message, you can still accept terms now, or call our support team.",
    backToTerm: 'Back to Terms',
    sincerely: 'Sincerely,',
    yourCare: 'Your Care Team at'
  },
  tooManyLoginAttempts: {
    oops: 'Too many verification attempts',
    errorMessage:
      'We’ve temporarily blocked access to this service for your security. Please try again in 30 minutes.',
    contactClinic: 'Contact your clinic or support program for help logging in.',
    mainPhone: 'Main Office Phone',
    nc: 'Navigating Care',
    customerSupport: 'Customer Support',
    day: 'Monday – Friday',
    time: '7:00am – 5:00pm (PST)',
    email: 'Email',
    phone: 'Phone'
  },
  verify: {
    verifyPatientInformation: 'Verify patient information',
    verifyBirthdate: "Verify patient's date of birth to get started",
    firstName: 'First Name',
    lastName: 'Last Name',
    DOB: 'Date of Birth',
    invalidDob: 'Date of birth must be a valid date.',
    month: 'Month',
    day: 'Day',
    year: 'Year',
    yearPlaceholder: 'YYYY',
    zipCode: 'Zip Code',
    validationMessage: 'Enter your 5-digit zip code.',
    verify: 'Verify',
    errorTitle: "Oops! Something didn't match up",
    errorMessage:
      'After five attempts, access to this service will be blocked. For help, <0>contact your clinic</0>.',
    aboutTitle: 'About Navigating Care',
    aboutDescription:
      'Your clinic uses Navigating Care to provide services to help you stay connected with your care team.',
    privacyPolicy:
      'Your privacy is important to us. For more information, review our <0>Terms of Use</0> and <1>Privacy Policy</1>.',
    dobRequired: 'Date of birth is required.',
    yearDigits: 'Year must be four digits.',
    digitalCompanion: 'Your digital health companion for',
    rememberMe:
      'Remember me on this device for 60 days. Uncheck if on a public device.'
  },
  viewMessages: {
    successMessage:
      'Success! You are ready to view messages from {{clinicName}}.',
    messages: 'Messages',
    backToTop: 'Back to top'
  },
  viewPreferences: {
    title: 'Preferences',
    mobileNo: 'Mobile Phone Number',
    emailAddress: 'Email Address',
    edit: 'Edit'
  },
  yourClinic: {
    title: 'Your Clinic',
    viewOnMap: 'View on Map',
    phone: 'Phone'
  },
  about: {
    title: 'About Us',
    detail:
      'Navigating Care delivers messages from your clinic and other resources to assist with your health care.'
  },
  errorMessage: {
    title: 'Oops, something went wrong.',
    error:
      "We're sorry, but this page is not available right now. Please try again later.",
    errorCode: 'Error code {{code}}'
  },
  footer: {
    about: 'About',
    help: 'Help',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    englishSelect: 'English',
    spanishSelect: 'Spanish'
  },
  layoutWrapper: {
    messages: 'Messages',
    yourClinic: 'Your Clinic',
    preferences: 'Preferences',
    signOut: 'Sign Out',
    help: 'Help'
  },
  common: {
    nc: 'Navigating Care',
    copy: `© ${year} Navigating Care, Inc`,
    url: '',
    phone: '1-800-925-4456',
    supportEmail: 'support@navigatingcare.com',
    infoEmail: 'info@navigatingcancer.com'
  }
}
export type Translation = typeof en
